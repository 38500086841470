<template>
  <div>
    <div class="detail-header">
      <p class="m-0 bold">Appointment Location</p>
      <b-button class="btn-filter" @click="handleClickGoogleMap"
        >Google map</b-button
      >
    </div>
    <div class="detail-body">
      <b-row>
        <b-col cols="12" md="6">
          <div class="list-detail">
            <p class="m-0 bold mw-100">Name</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full">
              {{ data.name }}
            </p>
          </div>

          <div class="list-detail">
            <p class="m-0 bold mw-100">District</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full">
              {{ data.districtName }}
            </p>
          </div>

          <div class="list-detail">
            <p class="m-0 bold mw-100">Zipcode</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full">
              {{ data.zipCode }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="list-detail">
            <p class="m-0 bold mw-100">Address</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full break-words">{{ data.address }}</p>
          </div>

          <div class="list-detail">
            <p class="m-0 bold mw-100">Sub District</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full">
              {{ data.subdistrictName }}
            </p>
          </div>

          <div class="list-detail">
            <p class="m-0 bold mw-100">Province</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full">
              {{ data.provinceName }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="data.offSitePrice">
        <b-col cols="12" md="6">
          <div class="list-detail">
            <p class="m-0 bold mw-100">ค่าบริการ</p>
            <p class="m-0 mr-2">:</p>
            <p class="m-0 w-full">
              {{ data.offSitePrice }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionAddress",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClickGoogleMap() {
      var url = `https://maps.google.com/maps?q=${this.data.latLongAsGeometry.latitude},${this.data.latLongAsGeometry.longitude}&hl=th&z=14&amp;output=embed`;

      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-color);
  padding: 10px;
  margin-top: 10px;
  p {
    color: white;
    font-weight: bold;
  }
}

.detail-body {
  background: white;
  padding: 10px;

  .list-detail {
    display: flex;
    margin-bottom: 5px;

    .mw-100 {
      min-width: 100px;
    }

    .w-full {
      width: 100%;
      text-align: right;
    }
  }
}

.break-words {
  word-break: break-word;
}
</style>
